import { AreaModel } from 'api-hooks/product';
import Flags from 'assets/geography/flags';
import Regions from 'assets/geography/regions';
import { trackViewItemCountry } from 'common/helpers/google-tag-manager';
import {
  CustomValue,
  SmartCustomizationUnit,
  applySmartCustomization,
} from 'common/repositories/customization';
import { NavigationRoutes } from 'common/routes';
import useNativeBridge from 'common/routes/bridge';
import { BridgeMessageType } from 'common/routes/bridge-types';
import { GenColor } from 'common/utils/random';
import { ListItemButton } from 'components/elements/button';
import Text from 'components/elements/text';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { CountryCardStyles, FlagStyles } from './styles.css';

interface CountryCardCustomization {
  root?: SmartCustomizationUnit<AreaModel, React.ComponentProps<'div'>>;
  label?: SmartCustomizationUnit<AreaModel, React.ComponentProps<typeof Text>>;
  flag?: {
    transform?: SmartCustomizationUnit<AreaModel, React.ComponentProps<'div'>>;
    image?: SmartCustomizationUnit<
      AreaModel,
      React.ComponentProps<typeof Flags>
    >;
  };
}
interface CountryCardProps {
  country: Pick<AreaModel, 'id' | 'name' | 'areaCode' | 'areaColor' | 'slug'>;
  customization?: CountryCardCustomization;
}

interface CountryListItemProps {
  country: Pick<AreaModel, 'id' | 'name' | 'areaCode' | 'slug'>;
  customization?: CountryCardCustomization & {
    trailing?: React.ReactNode;
  };
  disabled?: boolean;
  onClick?(id: string): void;
}

export const CountryCard = React.memo((props: CountryCardProps) => {
  const { country, customization } = props;
  const send = useNativeBridge({});

  const cardId = `kurosim--country-card--${useId()}`;
  const randColor = React.useRef(
    GenColor.generate({
      all: [196, 255],
    }).hex(),
  );
  const { push } = useKurosimNavigation();
  return (
    <button
      type="button"
      onClick={() => {
        // region GTM Analytics
        trackViewItemCountry(country?.slug);
        // endregion GTM Analytics

        // region FB send to Native
        send?.({
          data: { slug: country?.slug },
          type: BridgeMessageType.FacebookTrackItemCountry,
        });
        // endregion

        push(NavigationRoutes.Purchase, {
          paths: {
            slug: country?.slug,
          },
          query: undefined,
        });
      }}
      aria-labelledby={cardId}
    >
      <div
        {...applySmartCustomization(
          country,
          {
            className: CountryCardStyles.container,
            style: {
              backgroundImage: `linear-gradient(to bottom right, white, 20%, #${country.areaColor ?? randColor.current})`,
              width: 135,
              minWidth: 135,
              height: 90,
            },
          },
          [customization?.root],
        )}
      >
        <div
          role="presentation"
          {...applySmartCustomization(
            country,
            {
              className: CountryCardStyles.transformFlag,
            },
            [customization?.flag?.transform],
          )}
        >
          <Flags
            {...applySmartCustomization(
              country,
              {
                className: CountryCardStyles.flag,
                iso: country.areaCode,
              },
              [customization?.flag?.image],
            )}
          />
        </div>
        <Text
          id={cardId}
          textVariant="body1Medium"
          {...applySmartCustomization(
            country,
            {
              style: { position: 'absolute', zIndex: 'static' },
            },
            [customization?.label],
          )}
        >
          {country.name}
        </Text>
      </div>
    </button>
  );
});

interface RegionListItemProps {
  region: AreaModel;
  onClick?(): void;
  disabled?: boolean;
}

export function RegionListItem(props: RegionListItemProps) {
  const { t } = useTranslation();
  const { push } = useKurosimNavigation();
  const send = useNativeBridge({});
  const { region, disabled, onClick } = props;

  return (
    <ListItemButton
      disabled={disabled}
      leading={
        <div className={FlagStyles.borderContainer}>
          <Regions
            width={32}
            height={24}
            iso={region?.areaCode}
            style={{ borderRadius: 4 }}
          />
        </div>
      }
      onClick={
        onClick
          ? onClick
          : () => {
              // region GTM Analytic
              if (region?.slug) trackViewItemCountry(region?.slug);
              // endregion GTM Analytic

              // region FB send to Native
              send?.({
                data: { slug: region?.slug },
                type: BridgeMessageType.FacebookTrackItemCountry,
              });
              // endregion

              push(NavigationRoutes.Purchase, {
                paths: {
                  slug: region?.slug,
                },
                query: undefined,
              });
            }
      }
    >
      <div>
        <Text textVariant="body1Regular" ta="left">
          {region.name}
        </Text>
        <Text
          textVariant="body2Regular"
          ta="left"
          textColor="foregroundTertiary"
        >
          {`${region.totalCountries} ${t('sim:country', { count: region.totalCountries })}`}
        </Text>
      </div>
    </ListItemButton>
  );
}

export function CountryListItem(props: CountryListItemProps) {
  const { country, customization, onClick, disabled } = props;
  const { push } = useKurosimNavigation();
  const send = useNativeBridge({});

  return (
    <ListItemButton
      disabled={disabled}
      onClick={
        onClick
          ? () => onClick(country.id)
          : () => {
              // region GTM Analytic
              if (country?.slug) trackViewItemCountry(country?.slug);
              // endregion GTM Analytic

              // region FB send to Native
              send?.({
                data: { slug: country?.slug },
                type: BridgeMessageType.FacebookTrackItemCountry,
              });
              // endregion

              push(NavigationRoutes.Purchase, {
                paths: {
                  slug: country?.slug,
                },
                query: undefined,
              });
            }
      }
      trailing={customization?.trailing}
      leading={
        <div className={FlagStyles.borderContainer}>
          <Flags
            {...applySmartCustomization(
              country,
              {
                width: 32,
                height: 24,
                iso: country.areaCode,
                style: { borderRadius: 4 },
              },
              [customization?.flag?.image],
            )}
          />
        </div>
      }
      customization={{
        root: {
          style: CustomValue.combine({
            maxHeight: 51,
            minHeight: 51,
          }),
        },
      }}
    >
      {country.name}
    </ListItemButton>
  );
}
