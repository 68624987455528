import { Loader } from '@mantine/core';
import { useNetwork } from '@mantine/hooks';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import classNames from 'classnames';
import { ApiError, ExtendedApiResult } from 'common/api/model';
import notification from 'common/helpers/notification';
import React from 'react';
import structuralStyles from 'styles/layout.css';

import useGetParentRef, { ParentRefType } from './use-parent-ref';

interface Props<T> {
  query: UseInfiniteQueryResult<ExtendedApiResult<T>, ApiError>;
  type?: 'default' | 'standalone';
}

//https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollend_event
//just track when scrolling
export default function useKurosimInfiniteScroll<T>(props: Props<T>) {
  const { query, type = 'default' } = props;
  const { online } = useNetwork();
  const {
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = query;

  const standAloneRef = React.useRef<HTMLElement>(null);
  const appLayoutRef = useGetParentRef(ParentRefType.Scroll);

  React.useEffect(() => {
    const ref =
      type === 'default' ? appLayoutRef.current : standAloneRef.current;
    if (!ref) return;

    const onScroll = () => {
      const { scrollHeight, scrollTop, clientHeight } = ref;
      const bottom = scrollHeight - scrollTop - 50 <= clientHeight;

      if (isFetching || !hasNextPage || !online) return;

      if (bottom) {
        fetchNextPage();
      }
    };

    ref?.addEventListener('scroll', onScroll);
    return () => {
      ref?.removeEventListener('scroll', onScroll);
    };
  }, [appLayoutRef, fetchNextPage, hasNextPage, isFetching, online, type]);

  return {
    ...query,
    data: (query?.data?.pages || []).map((page) => page.data).flat(),
    ref: type === 'default' ? appLayoutRef : standAloneRef,
    Loader:
      isFetchingNextPage && !isLoading ? (
        <div
          className={classNames(
            structuralStyles.flexbox({ justify: 'center' }),
            structuralStyles.fill({ width: true }),
            structuralStyles.padding({ vertical: 16 }),
          )}
        >
          <Loader size={24} />
        </div>
      ) : null,
  };
}

interface UseLoadOnIntersectProps {
  loadNextPage(): void;
  hasNextPage: boolean;
  loading: boolean;
}

export function useLoadOnIntersect(props: UseLoadOnIntersectProps) {
  // Need refs for IntersectionObserver closure to access object
  const contextRef = React.useRef(props);
  contextRef.current = props;

  const ref = React.useRef<HTMLDivElement | null>(null);
  const observer = React.useRef<IntersectionObserver>();
  const load = React.useCallback((entries: IntersectionObserverEntry[]) => {
    const entry = entries?.[0];
    if (
      !entry ||
      !contextRef.current ||
      !entry.isIntersecting ||
      contextRef.current.loading ||
      !contextRef.current.hasNextPage
    )
      return;
    try {
      contextRef.current.loadNextPage();
    } catch (e) {
      console.error(e);
      notification.error({ message: e.message });
    }
  }, []);

  React.useEffect(() => {
    observer.current = new IntersectionObserver(load, {
      threshold: 0.1,
      rootMargin: '48px',
    });
    if (ref.current) {
      observer.current.observe(ref.current);
    }
    return () => observer.current?.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Trigger the scroll again
    if (!props || !ref.current || !observer.current || props.loading) return;
    observer.current.unobserve(ref.current);
    observer.current.observe(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loading]);

  return {
    ref,
    loading: props.loading,
    hasNextPage: props.hasNextPage,
  };
}

export function LoadOnIntersect(props: UseLoadOnIntersectProps) {
  // Setup infinite scroll
  const infiniteScroll = useLoadOnIntersect(props);

  if (!infiniteScroll) return null;

  return (
    <>
      <div style={{ height: 1, width: '100%' }} ref={infiniteScroll.ref} />
      {infiniteScroll.loading ? (
        <Loader my={8} size={24} />
      ) : // Ini diperlukan untuk buat placeholder
      infiniteScroll.hasNextPage ? (
        <div style={{ height: 40 }} />
      ) : undefined}
    </>
  );
}
