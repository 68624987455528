import { SaleInvoicePreparePaymentModel } from 'api-hooks/sale-invoice';
import { DataPlanTypeEnum } from 'common/constants/enum';
import { string2money } from 'common/utils/string';
import { TFunction } from 'i18next';

export const trackViewItemCountry = (slug: string) => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'View Item Country',
      country: slug,
    });
  }
};

export const trackCompleteRegistration = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Complete Registration',
    });
  }
};

export const trackAddToCart = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Add To Cart',
    });
  }
};

export const trackInitiateCheckout = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Initiate Checkout',
    });
  }
};

export const trackInitiatePayment = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Initiate Payment',
    });
  }
};

export const transformPaymentInfo = (
  data: SaleInvoicePreparePaymentModel,
  t: TFunction,
) => {
  const items = (data.items || []).map((item) => {
    const variantSections: string[] = [];
    if (item.metadata.validityDays > 1) {
      variantSections.push(`${item.metadata.validityDays} days`);
    } else {
      variantSections.push(`${item.metadata.validityDays} day`);
    }

    if (item.metadata.quotaInGb !== null) {
      variantSections.push(
        `${string2money(item.metadata.quotaInGb)} GB` +
          (item.metadata.dataType === DataPlanTypeEnum.Unlimited
            ? ' Unlimited'
            : ''),
      );
    }

    if (item.sim === null) {
      variantSections.push(t('common:new_esim'));
    } else {
      variantSections.push(
        item.sim.label
          ? t('common:recharge_extra', { extra: item.sim.label })
          : t('sim:recharge_esim'),
      );
    }

    const variantText = variantSections.join(' | ');
    return {
      id: item.id,
      discount: item.discountedPrice,
      item_name: item.metadata.name,
      item_variant: variantText,
      price: item.sellPrice,
      currency: item.currencyCode,
      quantity: item.qty,
    };
  });
  return {
    items,
    coupon: data.referral?.code,
    currency: 'IDR',
    payment_type: data.paymentMethod?.type,
    price: data.totalPayment,
  };
};

export const trackAddPaymentInfo = ({
  value,
  payment_type,
  items,
  currency = 'IDR',
  coupon,
}: {
  value: number;
  payment_type?: string;
  items: any[];
  coupon?: string;
  currency?: string;
}) => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'add_shipping_info',
      currency,
      value,
      payment_type,
      items,
      coupon,
    });
    window.dataLayer.push({
      event: 'Add Payment Info',
      currency,
      value,
      payment_type,
      items,
      coupon,
    });
  }
};

export const trackCoversion = ({
  conversion_value,
  transaction_id,
}: {
  conversion_value: number;
  transaction_id: string;
}) => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'GAdsConversionTracking',
      conversion_value,
      transaction_id,
    });
  }
};
