import classNames from 'classnames';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import Button, { ButtonProps } from 'components/elements/button';
import { Input } from 'components/elements/fields';
import { ButtonFieldProps } from 'components/elements/fields/button';
import MobileContainer from 'modules/components/mobile-container';
import React from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import structuralStyles, { FlexBoxType } from 'styles/layout.css';

import { footerStyles } from './footer.styles.css';

export type MainContainerProps = React.ComponentProps<'div'> & {
  flex?: FlexBoxType;
};

export interface MainButtonProps extends Omit<ButtonProps, 'type'> {
  type: 'main';
}

export type SubmitButtonProps = ButtonFieldProps;

interface AppFooterCustomization {
  root?: CustomizationUnit<React.ComponentProps<'div'>> & {
    border?: boolean;
  };
  mobileContainer?: CustomizationUnit<React.ComponentProps<'div'>>;
}

interface AppFooterProps {
  Above?: React.ReactNode;
  Below?: React.ReactNode;

  mainButtonProps?: MainButtonProps | SubmitButtonProps;
  rightActionIconProps?: ButtonProps;
  leftActionIconProps?: ButtonProps;
  mainContainerProps?: MainContainerProps;
  customization?: AppFooterCustomization;
}

export const mainButtonGeneratorProps = (
  methods: UseFormReturn<any>,
  onSubmit: SubmitHandler<any>,
) => {
  return {
    onClick: () => {
      methods.handleSubmit(onSubmit)();
    },
    loading: methods.formState.isSubmitting,
    disabled: !methods.formState.isValid,
    fullWidth: true,
  } as ButtonProps;
};

export default function AppFooter(props: AppFooterProps) {
  const {
    Above,
    Below,
    mainButtonProps,
    rightActionIconProps,
    leftActionIconProps,
    mainContainerProps,
    customization,
  } = props;

  return (
    <div
      {...applyCustomization(
        {
          className: classNames(
            structuralStyles.flexbox({
              direction: 'column',
              align: 'start',
              gap: 16,
            }),
            footerStyles.footerContainer({
              border: customization?.root?.border ?? true,
            }),
            structuralStyles.fill({ width: true }),
          ),
        },
        [customization?.root],
      )}
    >
      <MobileContainer>
        <div
          {...applyCustomization(
            {
              className: structuralStyles.flexbox({
                direction: 'column',
                align: 'stretch',
                gap: 16,
              }),
            },
            [customization?.mobileContainer],
          )}
        >
          {Above}
          <div
            {...mainContainerProps}
            className={classNames(
              structuralStyles.flexbox({
                gap: 16,
                ...mainContainerProps?.flex,
              }),
              structuralStyles.fill({ width: true }),
              mainContainerProps?.className,
            )}
          >
            {leftActionIconProps && <Button {...leftActionIconProps} />}
            {mainButtonProps && (
              <>
                {mainButtonProps.type === 'submit' ? (
                  <Input fullWidth {...mainButtonProps} type="submit" />
                ) : (
                  <Button
                    leftSection={<span />}
                    rightSection={<span />}
                    {...mainButtonProps}
                    type="button"
                  />
                )}
              </>
            )}
            {rightActionIconProps && <Button {...rightActionIconProps} />}
          </div>
          {Below}
        </div>
      </MobileContainer>
    </div>
  );
}
