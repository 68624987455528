/* eslint-disable @next/next/no-img-element */
import { AssetPaths } from 'common/constants/assets';
import { NavigationRoutes } from 'common/routes';
import { categorize } from 'common/utils/iterable';
import Separator from 'components/common/separator';
import { useIsSmallScreen } from 'hooks/use-is-small-screen';
import useKurosimNavigation from 'hooks/use-kurosim-navigation';
import useKurosimParentScroll from 'hooks/use-kurosim-parent-scroll';
import React from 'react';
import structuralStyles from 'styles/layout.css';

import sideNavigationStyles from './side-navigation.styles.css';
import ResponsiveAppActionComponent from '../actions';
import { AppLayoutActionsContext } from '../actions/context';
import { AppActionType } from '../actions/types';

interface SideNavigationProps {
  children: React.ReactNode;
}

export default function SideNavigation(props: SideNavigationProps) {
  const { children } = props;

  const { replace } = useKurosimNavigation();

  const isMobile = useIsSmallScreen();
  const scrollRef = useKurosimParentScroll();

  const allMenus = React.useContext(AppLayoutActionsContext);
  const { mainMenus = [], otherMenus = [] } = categorize(allMenus, (menu) =>
    menu.type === AppActionType.Navigation ? 'mainMenus' : 'otherMenus',
  );
  if (isMobile) return children;

  return (
    <div
      // Literal string class names are just comments for Ferdy and does not contain any styling information
      className={`main-container ${sideNavigationStyles.mainContainer}`}
      ref={(ref) => {
        scrollRef.current = ref;
      }}
    >
      <div
        className={`desktop-content-wrapper ${sideNavigationStyles.desktopContentWrapper}`}
      >
        <div
          className={`side-menu-desktop ${sideNavigationStyles.sideMenuDesktop}`}
        >
          <div
            className={`brand-logo ${sideNavigationStyles.brandLogo}`}
            onClick={() => replace(NavigationRoutes.MySim)}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              style={{
                position: 'absolute',
                left: -96,
                top: -96,
                width: 240,
                height: 240,
                transform: 'rotate(60deg)',
              }}
              loading="lazy"
              src={AssetPaths.GradientContent}
              alt=""
              role="presentation"
              aria-hidden="true"
            />
            <img
              src={AssetPaths.KurosimFeatTopremit}
              alt="kurosim"
              width={120}
              style={{
                zIndex: 1,
              }}
            />
          </div>
          <div className={structuralStyles.padding({ right: 24 })}>
            {mainMenus.map((menu) => {
              return <ResponsiveAppActionComponent {...menu} key={menu.key} />;
            })}
            <Separator gap={16} />
            {otherMenus.map((menu) => {
              return <ResponsiveAppActionComponent {...menu} key={menu.key} />;
            })}
          </div>
        </div>
        <div
          className={`main-content-desktop ${sideNavigationStyles.mainContentDesktop}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
